import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Banner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    // Add smooth scrolling behavior to the entire page
    document.documentElement.style.scrollBehavior = 'smooth';
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative bg-gradient-to-r from-[#F2B9AC] to-[#D9A296] overflow-hidden">
      <div className="absolute inset-0">
        <svg className="h-full w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <motion.path
            initial={{ pathLength: 0, opacity: 0 }}
            animate={{ pathLength: 1, opacity: 0.1 }}
            transition={{ duration: 2, ease: "easeInOut" }}
            fill="#ffffff"
            fillOpacity="0.1"
            d="M0,128L48,122.7C96,117,192,107,288,122.7C384,139,480,181,576,186.7C672,192,768,160,864,144C960,128,1056,128,1152,138.7C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></motion.path>
        </svg>
      </div>
      <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 max-w-7xl mx-auto">
        <div className="text-center">
        <motion.h2
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.8, delay: 0.2 }}
  className="font-artifex text-4xl sm:text-5xl md:text-6xl font-extrabold text-white leading-tight tracking-tight mb-4"
>
  <span className="block text-shadow-lg">GGDC</span>
  <motion.span
    initial={{ scale: 0.9 }}
    animate={{ scale: 1 }}
    transition={{ duration: 0.5, delay: 1 }}
    className="block text-[#402C2B] text-2xl sm:text-3xl md:text-4xl mt-2 bg-white bg-opacity-20 inline-block px-4 py-2 rounded-lg transform hover:scale-105 transition duration-300"
  >
    Accelerate Success, Enable Growth!
  </motion.span>
</motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="font-artifex mt-6 max-w-lg mx-auto text-xl sm:text-2xl text-white font-medium"
          >
            {['Real Estate', 'Strategy', 'Project, Program, Portfolio Management'].map((item, index) => (
              <motion.span
                key={item}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.2 }}
                className="inline-block px-3 py-1 bg-[#8C5A4F] bg-opacity-80 rounded-full m-1 shadow-md"
              >
                {item}
              </motion.span>
            ))}
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="mt-10 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => scrollToSection('services')}
              className="font-artifex w-full sm:w-auto inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-semibold rounded-full text-white bg-[#8C5A4F] hover:bg-[#A67063] transition duration-300 ease-in-out transform hover:shadow-lg"
            >
              Explore Our Services
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => scrollToSection('contact')}
              className="font-artifex w-full sm:w-auto inline-flex items-center justify-center px-8 py-4 border-2 border-white text-lg font-semibold rounded-full text-white hover:text-[#8C5A4F] hover:bg-white transition duration-300 ease-in-out transform hover:shadow-lg"
            >
              Contact Us
            </motion.button>
          </motion.div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0">
        <svg className="w-full h-16 text-white" viewBox="0 0 1440 100" xmlns="http://www.w3.org/2000/svg">
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, ease: "easeInOut" }}
            fill="currentColor"
            d="M0,0 C240,95 480,95 720,95 C960,95 1200,95 1440,0 V100 H0 V0 Z"
          ></motion.path>
        </svg>
      </div>
    </div>
  );
};

export default Banner;