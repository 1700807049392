import React, { useRef, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import abimg from './assets/comp.jpg';

const About = () => {
  const controls = useAnimation();
  const ref = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 },
    },
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <motion.div
      id="about"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="bg-gradient-to-b from-gray-100 to-white py-12 sm:py-16 md:py-24"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <motion.div variants={itemVariants} className="lg:w-1/2 lg:pr-12">
            <motion.h1
              variants={itemVariants}
              className="text-3xl sm:text-4xl font-extrabold text-gray-900 lg:text-5xl mb-6"
              style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
            >
              About <span className="text-[#8C5A4F]">GGDC</span>
            </motion.h1>
            <motion.div
              variants={itemVariants}
              className="space-y-4 sm:space-y-6 text-base sm:text-lg text-gray-700"
              style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
            >
              <p className="leading-relaxed">
                GGDC is a premier consultancy firm dedicated to transforming the real estate industry through strategic insight, meticulous execution, and unwavering commitment to excellence.
              </p>
              <p className="leading-relaxed">
                Founded by <span className="font-semibold text-[#402C2B]">Ginen G Dharamshi PMP</span>, a seasoned industry veteran with over 13 years of unparalleled expertise, our company specializes in providing bespoke solutions for real estate developers seeking to optimize IRR, project turnaround, execute growth strategies, and fortify risk management frameworks.
              </p>
              <p className="leading-relaxed">
                Our unique approach emphasizes collaboration, innovation, and a relentless pursuit of results, ensuring that every engagement yields tangible value and propels our clients towards sustained success in a dynamic market landscape.
              </p>
            </motion.div>
            <motion.div variants={itemVariants} className="mt-8 sm:mt-10">
              <motion.button
                onClick={() => scrollToSection('services')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-block px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white bg-gradient-to-r from-[#8C5A4F] to-[#402C2B] rounded-full shadow-md hover:from-[#402C2B] hover:to-[#8C5A4F] transition duration-300 ease-in-out transform hover:-translate-y-1"
                style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
              >
                Explore Our Services
              </motion.button>
            </motion.div>
          </motion.div>
          <motion.div variants={itemVariants} className="mt-12 lg:mt-0 lg:w-1/2">
            <div className="relative">
              <motion.img
                variants={itemVariants}
                src={abimg}
                alt="GGDC Expertise"
                className="rounded-lg shadow-2xl hover:shadow-3xl transition-shadow duration-300 ease-in-out object-cover object-center w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]"
              />
              <motion.div
                variants={itemVariants}
                className="absolute -bottom-4 -right-4 sm:-bottom-8 sm:-right-8 bg-white p-4 sm:p-6 rounded-lg shadow-xl"
              >
                <motion.p
                  variants={itemVariants}
                  className="text-2xl sm:text-3xl font-bold text-[#8C5A4F]"
                  style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
                >
                  13+
                </motion.p>
                <p className="text-sm sm:text-base text-gray-600" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>Years of Experience</p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;