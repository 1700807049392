import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const testimonials = [
  {
    id: 1,
    quote: "Ginen Dharamshi epitomizes professionalism, dedication, and expertise in the real estate industry. His strategic acumen and commitment to excellence have been instrumental in delivering successful projects and driving innovation. A true leader in every sense.",
    author: "Greg Munro",
    position: "Program Director NEOM"
  },
  {
    id: 2,
    quote: "I have had the privilege of collaborating with Ginen Dharamshi on multiple occasions, and each time, I have been impressed by his visionary approach, attention to detail, and unwavering integrity. He is a trusted advisor and a driving force for positive change in the industry.",
    author: "Said Mneimne",
    position: "Construction Manager"
  },
  {
    id: 3,
    quote: "Ginen Dharamshi's depth of knowledge and passion for real estate development are truly exceptional. His ability to navigate complex challenges, forge strategic partnerships, and deliver results sets him apart as a leader and a visionary in the field.",
    author: "Michael Brown",
    position: "Real Estate Consultant"
  },
  {
    id: 4,
    quote: "Working with Ginen Dharamshi has been a transformative experience. His collaborative spirit, innovative mindset, and unwavering dedication to client success make him a true asset to the real estate community. I highly recommend him for any project or initiative.",
    author: "Sandeep Shikre",
    position: "Architect"
  },
  {
    id: 5,
    quote: "Ginen Dharamshi's leadership and expertise have been invaluable in driving the success of our projects. His ability to anticipate challenges, devise innovative solutions, and foster collaboration among stakeholders sets him apart as a leader in the real estate industry.",
    author: "Rashesh Modi",
    position: "Project Manager"
  },
  {
    id: 6,
    quote: "As a seasoned industry professional, Ginen Dharamshi brings a wealth of experience, insights, and strategic vision to every project. His commitment to excellence, attention to detail, and focus on client satisfaction make him a trusted partner and advisor.",
    author: "Naresh Savla",
    position: "Real Estate Investor"
  },
  {
    id: 7,
    quote: "I have had the pleasure of witnessing Ginen Dharamshi's exemplary leadership and dedication firsthand. His forward-thinking approach, coupled with his strong work ethic and integrity, make him a standout figure in the real estate development landscape.",
    author: "Bhavin Garodia",
    position: "Financial Advisor"
  },
  {
    id: 8,
    quote: "Ginen Dharamshi's passion for excellence and unwavering commitment to client satisfaction make him a trusted partner and advisor in the real estate industry. His strategic insights and collaborative approach have been instrumental in driving the success of our projects.",
    author: "Madhur Gupta",
    position: "ED Lloyds Realty"
  }
];

const TestimonialCard = ({ quote, author, position, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
    id='testi'
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, delay: index * 0.1 } },
        hidden: { opacity: 0, y: 50 }
      }}
      className="bg-white rounded-lg shadow-md p-8 flex flex-col items-center text-center transform transition duration-500 hover:scale-105 relative overflow-hidden h-full"
    >
      <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-[#8C5A4F] to-[#402C2B]"></div>
      <FaQuoteLeft className="text-4xl text-[#8C5A4F] mb-4" />
      <p className="text-[#A67063] italic mb-6 text-lg overflow-y-auto flex-grow" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>{quote}</p>
      <FaQuoteRight className="text-4xl text-[#8C5A4F] mb-4" />
      <div className="mt-4">
        <h4 className="font-bold text-xl text-[#8C5A4F]" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>{author}</h4>
        <p className="text-sm text-[#A67063] mt-1" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>{position}</p>
      </div>
      <div className="absolute bottom-0 right-0 w-16 h-16 bg-[#F2B9AC] rounded-tl-full opacity-20"></div>
    </motion.div>
  );
};

const Testimonials = () => {
  const [showAll, setShowAll] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const visibleTestimonials = showAll ? testimonials.length : 3;

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
        hidden: { opacity: 0, y: 50 }
      }}
      className="py-20 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
            hidden: { opacity: 0, y: 50 }
          }}
          className="text-center mb-16"
        >
          <h3 className="text-4xl font-extrabold mb-4" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Voices of Excellence
          </h3>
          <p className="mt-4 text-xl" style={{ color: '#402C2B', fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Insights from Industry Leaders on Ginen Dharamshi's Impact
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.slice(0, visibleTestimonials).map((testimonial, index) => (
            <TestimonialCard key={testimonial.id} {...testimonial} index={index} />
          ))}
        </div>
        {!showAll && (
          <motion.div
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.6, delay: 0.3 } },
              hidden: { opacity: 0, y: 50 }
            }}
            className="text-center mt-12"
          >
            <button
              onClick={() => setShowAll(true)}
              className="bg-gradient-to-r from-[#8C5A4F] to-[#402C2B] hover:from-[#402C2B] hover:to-[#8C5A4F] text-white font-bold py-2 px-4 rounded"
              style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
            >
              View More Testimonials
            </button>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default Testimonials;
