import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaBriefcase } from 'react-icons/fa';

const CareerSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 },
    },
  };

  const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdYddbXHuylNKiZ4Y6uGGNykM8tGwDPszsAiR6VByMEFqiT-Q/viewform?usp=sf_link';

  const handleRedirect = () => {
    window.open(googleFormUrl, '_blank');
  };

  return (
    <motion.div
      id="career"
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={containerVariants}
      className="py-24 bg-gradient-to-r from-[#F2B9AC] to-[#F2E3E1]"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div className="text-center mb-20" variants={itemVariants}>
          <h3 className="text-5xl font-extrabold mb-6 text-[#402C2B] leading-tight" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            Join Our Team
          </h3>
          <p className="mt-4 text-xl text-[#614440] max-w-2xl mx-auto" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
            We're always looking for talented individuals to join our team. Click below to explore current opportunities and submit your application.
          </p>
        </motion.div>
        <motion.div
          variants={itemVariants}
          className="bg-white rounded-2xl shadow-lg p-10 transform transition duration-500 hover:shadow-2xl relative max-w-lg mx-auto"
        >
          <div className="absolute top-0 left-0 w-16 h-16 bg-[#F2B9AC] rounded-br-full opacity-30"></div>
          <div className="absolute bottom-0 right-0 w-16 h-16 bg-[#F2B9AC] rounded-tl-full opacity-30"></div>
          <div className="text-center">
            <FaBriefcase className="mx-auto text-5xl text-[#8C5A4F] mb-6" />
            <p className="text-lg text-[#614440] mb-8" style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}>
              Ready to take the next step in your career? We'd love to hear from you!
            </p>
            <motion.button
              onClick={handleRedirect}
              variants={itemVariants}
              className="w-full py-4 px-6 border border-transparent rounded-lg shadow-sm text-lg font-semibold text-white bg-gradient-to-r from-[#8C5A4F] to-[#402C2B] hover:from-[#402C2B] hover:to-[#8C5A4F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8C5A4F] transition duration-300 transform hover:scale-105"
              style={{ fontFamily: "'Artifex CF Demi Bold', sans-serif" }}
            >
              Apply Now
            </motion.button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default CareerSection;